<template>
  <v-container class="py-1 px-2">
    <v-autocomplete
      v-model="selectedPoi"
      :items="poisOfSite"
      item-text="feature.properties.name"
      item-value="id"
      :label="$t('POI & annotation search')"
      class="my-0 pb-2"
      hide-details
      return-object
      clearable
      hide-no-data
      outlined
      dense
    >
      <template v-slot:item="data">
        <v-list-item-avatar>
          <sprite-icon
            v-if="data.item.feature.properties.icon"
            :icon="data.item.feature.properties.icon"
          ></sprite-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.feature.properties.name"></v-list-item-title>
          <v-list-item-subtitle v-html="data.item.levelName"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<style scoped>
.v-text-field.v-text-field--enclosed {
  border-radius: 0;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import constants from '~/shared/constants';
import { center as turfCenter, polygon as turfPolygon } from '@turf/turf';
import SpriteIcon from './SpriteIcon';

export default {
  name: 'PoiSearch',
  props: ['map'],

  data() {
    return {
      queryString: '',
      selectedPoi: null,
      poisOfSite: []
    };
  },

  computed: {
    ...mapGetters('feature', ['featuresByLevel']),
    ...mapGetters('level', ['editedLevel', 'levels']),
    ...mapGetters('customImage', ['imagesByLevel'])
  },

  methods: {
    ...mapActions('level', { changeLevel: 'change' }),
    getSavedPoiList() {
      this.poisOfSite = [];
      for (const levelId of Object.keys(this.featuresByLevel)) {
        const features = this.featuresByLevel[levelId];
        this.poisOfSite = this.poisOfSite.concat(
          features.filter(
            (element) =>
              element.feature.properties._type === constants.FEATURE_TYPES.POI ||
              element.feature.properties._type === constants.FEATURE_TYPES.ANNOTATION
          )
        );
      }

      for (const levelId in this.imagesByLevel) {
        for (const image of this.imagesByLevel[levelId]) {
          if (image.meta.name) {
            this.poisOfSite.push({
              feature: {
                ...turfCenter(
                  turfPolygon([[...image.meta.coordinates, image.meta.coordinates[0]]])
                ),
                properties: {
                  name: image.meta.name,
                  icon: 'maki_art-gallery'
                }
              },
              levelId: levelId
            });
          }
        }
      }

      if (this.levels && this.levels.length > 0) {
        this.poisOfSite.forEach((poiFeature) => {
          if (poiFeature.feature.properties._type === constants.FEATURE_TYPES.ANNOTATION) {
            poiFeature.feature.properties.icon = 'maki_information';
          }
          poiFeature.levelName = this.levels.find((level) => level.id === poiFeature.levelId).name;
        });
      }
    }
  },

  watch: {
    selectedPoi() {
      if (this.editedLevel && this.selectedPoi) {
        if (this.editedLevel.id !== this.selectedPoi.levelId) {
          this.changeLevel(this.selectedPoi.levelId);
        }
        this.map.flyTo({
          center: this.selectedPoi.feature.geometry.coordinates
        });
      }
    },
    featuresByLevel: {
      handler() {
        this.getSavedPoiList();
      },
      immediate: true
    }
  },

  components: {
    SpriteIcon
  }
};
</script>
